<template>
  <TopNav></TopNav>
  <h1>Taksi Ücreti Hesaplama</h1>
  <fieldset>
    <legend>Taksi Ücreti Hesaplama Aracı</legend>
    <select name="cars" id="cars" v-model="selected">
      <option value="1">1. Adana</option>
      <option value="6">6. Ankara</option>
      <option value="7">7. Antalya</option>
      <option value="16">16. Bursa</option>
      <option value="17">17. Çanakkale</option>
      <option value="21">21. Diyarbakır</option>
      <option value="22">22. Edirne</option>
      <option value="27">27. Gaziantep</option>
      <option value="34">34. İstanbul</option>
      <option value="35">35. İzmir</option>
      <option value="42">42. Konya</option>
      <option value="53">53. Rize</option>
      <option value="55">55. Samsun</option>
    </select>
    <br>
    <input type="number" v-model.number="inputMesafe" id="km" step="any" min="0"> <label for="km"><abbr title="kilometre">㎞</abbr></label>
    <br><br>
    Ücret: <span class="blue">{{ ucret() }} ₺</span>
  </fieldset>
</template>

<script>
import TopNav from './components/TopNav.vue'

export default {
  name: "TaksiUcreti",
  data() {

    var ucretArrayi = new Array();
    // km başı, açılış, minimum
    ucretArrayi[1] = [21, 17, 80];
    ucretArrayi[6] = [20, 25, 75];
    ucretArrayi[7] = [25, 20, 60];
    ucretArrayi[16] = [22, 23, 70];
    ucretArrayi[17] = [25, 50, 100];
    ucretArrayi[21] = [13, 15, 60];
    ucretArrayi[22] = [7, 28, 60];
    ucretArrayi[27] = [10, 30, 30];
    ucretArrayi[34] = [17.61, 24.55, 90];
    ucretArrayi[35] = [22, 12, 50];
    ucretArrayi[42] = [20, 20, 60];
    ucretArrayi[53] = [20, 50, 80];
    ucretArrayi[55] = [17, 46, 90];

    return {
      selected: 34,
      inputMesafe: 0,
      ucretArray: ucretArrayi
    }
  },
  components: {
    TopNav
  },
  methods: {
    ucret: function () {
      let ucret;
      var hesaplananUcret = this.ucretArray[this.selected][0] * this.inputMesafe + this.ucretArray[this.selected][1];
      if (hesaplananUcret < this.ucretArray[this.selected][2]) {
        ucret = this.ucretArray[this.selected][2];
      } else {
        ucret = hesaplananUcret;
      }

      const ucretNumber = Number(ucret);
      const formatter = new Intl.NumberFormat('tr-TR', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });

      return formatter.format(ucretNumber);
    }
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

body {
  margin: 1em auto;
  margin-top: 0;
  max-width: 40em;
  font-family: Arial, Helvetica, sans-serif;
}

label {
  display: inline-block;
}

footer section a {
  margin: 20px;
}

.blue {
    color: blue;
}
</style>
